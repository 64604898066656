@font-face {
  font-family: "Radikal-bd";
  src: url("./assets/font/Radikal-Bold.ttf");
}

@font-face {
  font-family: "Radikal-rg";
  src: url("./assets/font/Radikal-Regular.ttf");
}
@font-face {
  font-family: "Gotham-rg";
  src: url("./assets/font/GothaProReg.otf");
}
@font-face {
  font-family: "SF-mono";
  src: url("./assets/font/sf-mono-cufonfonts-webfont/SFMonoRegular.woff");
}

body {
  padding: 0;
  margin: 0;
  font-family: "Radikal-rg", sans-serif;
  font-weight: 40;
}
html {
  scroll-behavior: smooth;
}
.title {
  font-family: "Radikal-bd";
  font-weight: 600;
  font-size: 32px;
  color: #222222;
}
.banner {
  overflow: hidden;
  // background: 
  // linear-gradient(
  //     90deg,
  //     rgba(0, 179, 173, 0.37608546836703427) 0%,
  //     rgba(0, 0, 0, 0.4881302862942052) 39%
  //   ),
    // url("./assets/img/tgreen_header.png") top;
  background-size: contain;
  background-repeat: no-repeat;
  color: white;
  width: "100%";
}

.subtitle {
  font-family: "Radikal-bd";
  font-weight: 300;
  font-size: 23px;
  color: #222222;
}
.header-content {
  padding-top: 120px;
  min-height: 100vh;
  justify-content: center;
  display: flex;
  align-items: center;
  @media (min-width: 900px) {
    padding: 0 2vw;
  }
  h3 {
    font-size: 68px;
    font-family: "Radikal-bd", sans-serif;
    @media (max-width: 700px) {
      font-size: 40px;
    }
  }
  p {
    font-size: 24px;
    line-height: 1.5em;
    @media (max-width: 700px) {
      font-size: 20px;
    }
  }
}
.navbar-brand {
  color: white;
  font-family: "Radikal-bd";
  position: fixed;
  z-index: 4;
}

footer {
  font-size: 12px;
  color: grey;
  font-weight: 700;
  a {
    color: grey;
  }
}
.click {
  cursor: pointer;
}
.project-container {
  box-shadow: 1px 2px 2px 2px rgba(40, 201, 241, 0.2);
  cursor: pointer;
  transition: all 0.5s ease;
  padding: 1rem 1.4rem;
  border-radius: 10px;
  &:hover {
    transform: translate(0px, 10px);
  }
  img {
    height: 180px;
    object-fit: contain;
  }
}
a {
  color: #00b3ad !important;
  &:hover {
    color: #00b3ad !important;
  }
}
.featured {
  a {
    color: inherit;
  }
  font-size: 13px;
  font-family: "SF-mono", monospace;
  color: #00b3ad;
}
.py-space {
  padding-top: 8vh;
  padding-bottom: 8vh;
}

.btn {
  border: none;
  border-radius: 50px;
  font-size: 19px;
  padding: 0.4rem 2rem;
}
.btn-blue {
  color: white;
  box-shadow: 0px 15px 35px 0px rgba(40, 201, 241, 0.3);
  background-color: #0aacee;
  &:hover {
    color: white;
  }
}
.btn-green {
  color: white;
  box-shadow: 0px 15px 35px 0px rgba(40, 201, 241, 0.3);
  background-color: #00b3ad;
  &:hover {
    color: white;
  }
}

.iframe-responsive {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.7%;
  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}
.project-icon {
  width: 150px;
}
.font20 {
  font-size: 20px !important;
}
.download {
  a {
    font-size: 25px;
    color: #00b3ad;
  }
}
